import '../App.css';
import Account from '../Account';

import React, { useState } from "react";
import LoadingComponent from './LoadingComponent';

function AddPatientComponent({setHeader, initialTrialOn}) {
  
  const [trialOn, setTrialOn] = useState(initialTrialOn);
  const [values, setValues] = useState([initialTrialOn==-1?"default":initialTrialOn,"default", "default", "default", "default", "default"]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [state, setState] = useState("ready");
  const [patientId, setPatientId] = useState("");
  
  function handleValues(index, value){
    var newValues = values.map((val, ind) => {
      if (ind === index) {
        return value;
      } else {
        return val;
      }
    })
    setValues(newValues);
  }

  function addPatientAvailable(){
    const trial = Account.trials[trialOn];
    return (values[0] != "default" && 
     values[1] != "default" && 
    (values[2] != "default" || trial.variable1 === undefined) &&
    (values[3] != "default" || trial.variable2 === undefined) &&
    (values[4] != "default" || trial.variable3 === undefined) &&
    (values[5] != "default" || trial.variable4 === undefined))
  }

  async function addPatient(){
    setPatientId("");
    let trial = Account.trials[trialOn];
    let patientId = await trial.addPatient(values, "cedrictominson@hotmail.com");
    console.log(patientId);
    return patientId;
  }
  
  function options(){
    var optionArray = [];
    if (trialOn === -1){
      optionArray.push(<option hidden disabled selected value = "default">Select a Trial</option>)
    }
    else{
      optionArray.push(<option hidden disabled value = "default">Select a Trial</option>)
    }
    for (var i = 0 ; i < Account.trials.length; i ++){
      if (trialOn === i){
        optionArray.push(<option selected value={i}> {Account.trials[i].name} </option>)
      }
      else{
        optionArray.push(<option value={i}> {Account.trials[i].name} </option>)
      }
    }
    return optionArray;
  }

  function siteComponents(){
    var trial = Account.trials[trialOn];
    var optionArray = [];
    optionArray.push(<option hidden disabled value = "default">Select a Site</option>)
    for (var i = 0 ; i < trial.sites.length; i ++){
      if (Account.sites[trial.id].includes(trial.sites[i])){
        optionArray.push(<option value={i}>{trial.sites[i]} </option>)
      }
      else{
        optionArray.push(<option disabled value={i}>{trial.sites[i]} </option>)
      }

    }
    return optionArray;
  }

  function variable1Components(){
    var trial = Account.trials[trialOn];
    if (trial.variable1 === undefined){
      return;
    }
    var optionArray = [];
    optionArray.push(<option hidden disabled value = "default">Select: {trial.variable1}</option>)
    for (var i = 0 ; i < trial.variable1Array.length; i ++){
      optionArray.push(<option value={i}>{trial.variable1Array[i]} </option>)
    }
    return optionArray;
  }

  function variable2Components(){
    var trial = Account.trials[trialOn];
    if (trial.variable2 === undefined){
      return;
    }
    var optionArray = [];
    optionArray.push(<option hidden disabled value = "default">Select: {trial.variable2}</option>)
    for (var i = 0 ; i < trial.variable2Array.length; i ++){
      optionArray.push(<option value={i}>{trial.variable2Array[i]} </option>)
    }
    return optionArray;
  }

  function variable3Components(){
    var trial = Account.trials[trialOn];
    if (trial.variable3 === undefined){
      return;
    }
    var optionArray = [];
    optionArray.push(<option hidden disabled value = "default">Select: {trial.variable3}</option>)
    for (var i = 0 ; i < trial.variable3Array.length; i ++){
      optionArray.push(<option value={i}>{trial.variable3Array[i]} </option>)
    }
    return optionArray;
  }

  function variable4Components(){
    var trial = Account.trials[trialOn];
    if (trial.variable4 === undefined){
      return;
    }
    var optionArray = [];
    optionArray.push(<option hidden disabled value = "default">Select: {trial.variable4}</option>)
    for (var i = 0 ; i < trial.variable4Array.length; i ++){
      optionArray.push(<option value={i}>{trial.variable4Array[i]} </option>)
    }
    return optionArray;
  }

  return (
      <div className='add-patient-div' id='add-patient-div'>
        <div className='description-div'>
          <div className='description-div-text1'>
            Randomize a New Patient to A Trial
          </div>
          <div className='description-div-text2'>
          Select a trial, as well as all applicable strata. After which, you will be able to randomize a patient to this trial.
          </div>
        </div>
        {confirmOpen?<div className='confirm-patient-div'>
          <div className='confirm-title-div'>Confirm Patient Details</div>
          <div className='variable-div'>
          Trial: <font color="#656565">{" " + Account.trials[trialOn].name}</font>
          </div>
          <div className='variable-div'>Site: <font color="#656565">{" " + Account.trials[trialOn].sites[values[1]]} </font></div>
          {Account.trials[trialOn].variable1 === undefined?<div/>:
          <div className='variable-div'>{Account.trials[trialOn].variable1+ ":"} <font color="#656565">{" " + Account.trials[trialOn].variable1Array[values[2]]}</font></div>}
          {Account.trials[trialOn].variable2 === undefined?<div/>:
          <div className='variable-div'>{Account.trials[trialOn].variable2+ ":"} <font color="#656565">{" " + Account.trials[trialOn].variable2Array[values[3]]}</font></div>}
          {Account.trials[trialOn].variable3 === undefined?<div/>:
          <div className='variable-div'>{Account.trials[trialOn].variable3+ ":"} <font color="#656565">{" " + Account.trials[trialOn].variable3Array[values[4]]}</font></div>}
          {Account.trials[trialOn].variable4 === undefined?<div/>:
          <div className='variable-div'>{Account.trials[trialOn].variable4+ ":"} <font color="#656565">{" " + Account.trials[trialOn].variable4Array[values[5]]}</font></div>}
          {state === "ready" &&  <a href="#" className='randomize-patient-div-2' onClick={() => {
            setState("loading");
            addPatient().then((id) => {
              console.log(id);
              if (id === "failed"){
                setState("failed");
              }
              else{
                setPatientId(id);
                setState("success");
              }
            })}
            }>Randomize Patient</a>
            }
          {state === "loading" && 
            <div className='loading-parent-add-patient'>
              <LoadingComponent text="Adding Patient..."/>
            </div>}
          {state === "failed" && <div/>}
          {state === "success" && <div className='success-div'>Patient Successfully Randomized</div>}
          {state === "success" && <div className='variable-div'>Patient ID: <font color="#656565">{patientId}</font></div>}
          {state === "failed" || state ==="success" && <a href="#" className='cancel-randomize-patient-div' onClick={() => {
            setConfirmOpen(false);
            setState("ready");
            setPatientId("");
            setValues(["default","default", "default", "default", "default", "default"]);
            setTrialOn(-1);
            }}>Close</a>}
          {state === "ready" && <a href="#" className='cancel-randomize-patient-div' onClick={() => {
            setConfirmOpen(false);
            setPatientId("");
            setValues(["default","default", "default", "default", "default", "default"]);
            setTrialOn(-1);
            }}>Cancel</a>}
        
        </div>:<div className='add-patient-sub-div'>
          <div className='trial-label-div'>Trial</div>
          <select style={{color: values[0] === "default"?'#ffffffaf':'white'}} className="classic" id='trial-select' 
            onChange={event => {
              setTrialOn(event.target.value);
              setValues([event.target.value, "default", "default", "default", "default", "default"]);
              setHeader(Account.trials[event.target.value].nameShorthand)}}>
            {options()}
          </select>
        {(values[0] === "default") ? (<div/>) :
        (<div>
          <div className='trial-label-div'>Site</div>
          <select style={{color: values[1] === "default"?'#ffffffaf':'white'}} value={values[1]} className="classic" id='site-select' 
            onChange={event => handleValues(1, event.target.value)}>
          {siteComponents()}
        </select>
      </div>)
      }
      {(values[0] === "default" || Account.trials[trialOn].variable1 === undefined) ? (<div/>) :
      (<div>
        <div className='trial-label-div'>{Account.trials[trialOn].variable1}</div>
        <select style={{color: values[2] === "default"?'#ffffffaf':'white'}}
        value={values[2]} className="classic" id='variable1-select' onChange={event => handleValues(2, event.target.value)}>
          {variable1Components()}
          </select>
      </div>)
      }
      {(values[0] === "default" || Account.trials[trialOn].variable2 === undefined) ? (<div/>) :
      (<div>
        <div className='trial-label-div'>{Account.trials[trialOn].variable2}</div>
        <select style={{color: values[3] === "default"?'#ffffffaf':'white'}}
        value={values[3]} className="classic" onChange={event => handleValues(3, event.target.value)}>
          {variable2Components()}
        </select>
      </div>)
      }
      {(values[0] === "default" || Account.trials[trialOn].variable3 === undefined) ? (<div/>) :
      (<div>
        <div className='trial-label-div'>{Account.trials[trialOn].variable3}</div>
        <select style={{color: values[4] === "default"?'#ffffffaf':'white'}}
        value={values[4]} className="classic" onChange={event => handleValues(4, event.target.value)}>
          {variable3Components()}
        </select>
      </div>)
      }
      {(values[0] === "default" || Account.trials[trialOn].variable4 === undefined) ? (<div/>) :
      (<div>
        <div className='trial-label-div'>{Account.trials[trialOn].variable4}</div>
        <select style={{color: values[5] === "default"?'#ffffffaf':'white'}}
        value={values[5]} className="classic" onChange={event => handleValues(5, event.target.value)}>
          {variable4Components()}
        </select>
      </div>)
      }
      {addPatientAvailable()? (<a href="#" className='randomize-patient-div' onClick={() => setConfirmOpen(true)}>Randomize Patient</a>): <div/>}
        </div>
      } 
        
      </div>
    )
}

export default AddPatientComponent;